/* You can add global styles to this file, and also import other style files */
@import "@ui/styles/material";
@import "@ui/styles/themes/light";
@import "@ui/styles/themes/dark";
@import "@ui/styles/global";
@import "@forms/styles/global";
@import "@ctrl/ngx-emoji-mart/picker";
@import "froala-editor/css/froala_editor.pkgd.min";
@import "froala-editor/css/froala_style.min";
@import "@ui/styles/froala";
