.mat-mdc-paginator-page-size-label,
.mat-mdc-paginator-range-label {
  color: var(--color-txt-icon-3) !important;
}

.mat-mdc-paginator-navigation-first,
.mat-mdc-paginator-navigation-previous,
.mat-mdc-paginator-navigation-next,
.mat-mdc-paginator-navigation-last {
  color: var(--color-txt-icon-3) !important;

  &:hover {
    color: var(--color-txt-icon-1) !important;
  }

  &:disabled {
    color: var(--color-txt-icon-4) !important;
  }
}
