// -------------------------------------
// Colors
// -------------------------------------
//
// USE ONLY WITH THEME VARIABLES, DONT USE AS IN USAGE EXAMPLE
//
// -------------------------------------
// usage:
// @use "@ui/styles/colors"
//
// color: colors.$red
//
// -------------------------------------

$red: #ff2400;
$grenadier-red: #db2103;
$milano-red: #d11700;
$teal: #00cfa6;
$yellow: #fac600;
$blue: #2c99ff;
$purple: #AD00FF;
$gray-1: #313334;
$gray-2: #454849;
$gray-3: #54585a;
$gray-4: #9d9b9b;
$gray-5: #c6c6c6;
$gray-6: #eeeeee;
$gray-7: #f7f7f7;
$gray-8: #f1f1f1;
$gray-9: #f6f6f8;
$gray-10: #e1e1e1;
$gray-11: #ececec;
$white: #ffffff;
$black: #000000;
$dark-gray-1: #0d1016;
$dark-gray-1x: #13171f;
$dark-gray-2: #1b1f28;
$dark-gray-3: #232834;
$dark-gray-3x: #2d3442;
$dark-gray-4: #444852;
$dark-gray-5: #80858f;
$dark-gray-6: #a7aab1;
$dark-gray-7: #c2c4c9;
$dark-gray-8: #acacac;
