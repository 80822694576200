@import "../colors";
@import "../shadows";

:root.pu-dark-theme {
  color-scheme: dark;

  --color-brand-primary: #{$red};
  --color-brand-secondary: #{$teal};
  --color-additionals-danger: #{$red};
  --color-additionals-success: #{$teal};
  --color-additionals-warning: #{$yellow};
  --color-additionals-info: #{$blue};

  --color-transparent: transparent;

  --color-border: #{$dark-gray-2};

  // Buttons
  --color-btn-txt: #{$white};
  --color-btn-txt-pressed: #{$dark-gray-7};
  --color-btn-bg-pressed: #{$milano-red};

  --color-btn-primary-bg: #{$red};
  --color-btn-primary-bg-hover: #{$grenadier-red};

  --color-btn-secondary-bg: #{$dark-gray-3};
  --color-btn-secondary-bg-hover: #{$red};

  --color-btn-tertiary-bg: #{$dark-gray-4};
  --color-btn-tertiary-bg-hover: #{$red};

  --color-btn-quaternary: #{$dark-gray-5};
  --color-btn-quaternary-hover: #{$gray-7};
  --color-btn-quaternary-pressed: #{$dark-gray-4};

  // Backgrounds
  --color-bg-1: #{$dark-gray-1};
  --color-bg-2: #{$dark-gray-1x};
  --color-bg-3: #{$dark-gray-2};
  --color-bg-4: #{$dark-gray-3};
  --color-bg-4x: #{$dark-gray-3x};
  --color-bg-5: #{$dark-gray-5};
  --color-bg-6: #{$dark-gray-6};

  // Shadows
  --shadow-header-top: #{$box-shadow-to-bottom-dark};
  --shadow-header-bottom: #{$box-shadow-to-bottom-light};
  --shadow-navbar: #{$box-shadow-to-top-dark};
  --shadow-control-dropdown: #{$box-shadow-mini-dark};

  // Gradients
  --gradients-skeleton-blur: linear-gradient(
      93.37deg,
      rgba(255, 255, 255, 0) 0.89%,
      rgba(255, 255, 255, 0.6) 48.66%,
      rgba(255, 255, 255, 0) 96.17%
  );
  --gradient-tab-highlighting: linear-gradient(90deg, rgba(255, 36, 0, 0) 0%, #ff2400 50.52%, rgba(255, 36, 0, 0) 100%);
  --gradient-horiz-tab-scroll-prev: linear-gradient(90deg, #0d1016 0%, rgba(13, 16, 22, 0.00) 100%);
  --gradient-horiz-tab-scroll-next: linear-gradient(270deg, #0d1016 0%, rgba(13, 16, 22, 0.00) 100%);
  --gradient-horiz-tab-scroll-prev-3: linear-gradient(90deg, #{$dark-gray-2} 0%, #{$dark-gray-2} 10%, rgba(13, 16, 22, 0.00) 100%);
  --gradient-horiz-tab-scroll-next-3: linear-gradient(270deg, #{$dark-gray-2} 0%, #{$dark-gray-2} 10%, rgba(13, 16, 22, 0.00) 100%);
  --gradient-border-hover: linear-gradient(139.58deg, #00cfa6 0%, rgba(0, 207, 166, 0) 100%);
  --gradient-divider-horizontal: linear-gradient(
      90deg,
      rgba(30, 35, 46, 0) 0%,
      rgba(128, 133, 143, 0.252417) 27.6%,
      rgba(128, 133, 143, 0.5) 52.62%,
      rgba(128, 133, 143, 0.291283) 74.48%,
      rgba(13, 16, 22, 0) 100%
  );
  --gradient-divider-vertical: linear-gradient(180deg, rgba(30, 35, 46, 0) 0%, rgba(128, 133, 143, 0.252417) 27.6%, rgba(128, 133, 143, 0.5) 52.62%, rgba(128, 133, 143, 0.291283) 74.48%, rgba(13, 16, 22, 0) 100%);
  --gradient-shadow: linear-gradient(180deg, #0d1016 23.33%, rgba(13, 16, 22, 0) 98.32%);
  --gradient-shadow-rev: linear-gradient(0deg, #0d1016 23.33%, rgba(13, 16, 22, 0) 98.32%);
  --gradient-shadow-to-top: linear-gradient(0deg, #1b1f28 0%, rgba(27, 31, 40, 0.00) 100%);

  // Text and icon
  --color-txt-icon-1: #{$white};
  --color-txt-icon-2: #{$dark-gray-7};
  --color-txt-icon-3: #{$dark-gray-5};
  --color-txt-icon-4: #{$dark-gray-4};
  --color-txt-icon-5: #{$dark-gray-6};
  --color-txt-icon-6: #{$dark-gray-3};

  // Support
  --color-support-menu-bg-hover: #{$dark-gray-3x};

  // Balance
  --color-balance-txt: #{$dark-gray-4};
  --color-balance-horizontal-txt: #{$white};
  --color-balance-icon: #{$dark-gray-6};
  --color-balance-bg: #{$dark-gray-1};
  --color-balance-skin-share-bg: #{$dark-gray-3};
  --color-balance-skin-txt: #{$white};
  --gradient-balance-skin-border: linear-gradient(139.58deg, #ff2400 0%, rgba(0, 207, 166, 0) 100%);

  // Theme switcher
  --color-theme-switcher-border: #{$gray-7};
  --color-theme-switcher-slider-bg: transparent;
  --color-theme-switcher-icon: #{$white};

  // slide toggle
  --color-slide-toggle-track-active: #{$teal};
  --color-slide-toggle-track: #{$dark-gray-4};
  --shadow-slide-toggle-track: inset -1px 1px 2px rgba(13, 16, 22, 0.4);
  --gradient-slide-toggle-handle: linear-gradient(144.73deg, #e3e3e3 15.19%, #fff 56.91%);
  --color-slide-toggle-handle-bg: #{$white};
  --color-slide-toggle-handle-border: #{$dark-gray-8};
  --color-slide-toggle-handle-border-active: #{$teal};

  // Tabs
  --color-tabs-icon: #{$dark-gray-5};
  --color-tabs-icon-hover: #{$white};
  --color-tabs-icon-active: #{$red};
  --color-tabs-txt: #{$dark-gray-5};
  --color-tabs-txt-active-1: #{$white};
  --color-tabs-txt-hover-1: #{$white};
  --color-tabs-divider: #{$dark-gray-2};

  // Sub category tabs
  --color-sub-category-txt: #{$dark-gray-5};
  --color-sub-category-txt-active: #{$white};
  --color-sub-category-txt-hover: #{$dark-gray-5};
  --color-sub-category-tab-strk: #{$dark-gray-4};
  --color-sub-category-tab-strk-hover: var(--gradient-border-hover);
  --color-sub-category-tab-bg: linear-gradient(95.29deg, rgba(0, 207, 166, 0.2) 0%, rgba(0, 207, 166, 0) 100%);
  --color-sub-category-tab-strk-active: linear-gradient(113.11deg, #00cfa6 0%, #444852 100%);

  // Label
  --color-label-in-bottom-text: #{$white};
  --color-label-in-bottom-bg: rgba(13, 16, 22, 0.65);
  --color-label-txt: #{$white};
  --color-label-new-bg: rgba(0, 207, 166, 0.6);
  --color-label-exclusive-bg: rgba(255, 36, 0, 0.6);
  --color-label-freespins-bg: rgba(13, 16, 22, 0.6);
  --color-label-game-tile: #{$dark-gray-7};
  --color-label-game-empty-star: #{$white};
  --color-label-game-darker: rgba(13, 16, 22, 0.7);
  --color-label-winner-bg: rgba(13, 16, 22, 0.95);
  --color-label-winner-bg-hover: #{$dark-gray-1};

  //Filters
  --filter-color-tabs-icon: brightness(0) saturate(100%) invert(60%) sepia(3%) saturate(1090%) hue-rotate(182deg) brightness(87%) contrast(88%);
  --filter-color-tabs-icon-hover: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(1%) hue-rotate(343deg) brightness(102%) contrast(101%);
  --filter-tabs-icon-active: brightness(0) saturate(100%) invert(28%) sepia(100%) saturate(5905%) hue-rotate(6deg) brightness(104%) contrast(105%);
  --filter-brightness-0: brightness(0%);

  // Controls toggle
  --color-toggle-bg: #{$dark-gray-5};
  --color-toggle-bg-active: #{$teal};
  --color-toggle-circle: linear-gradient(141.95deg, #dddddd 25.7%, #939393 100%);
  --color-toggle-circle-active: linear-gradient(141.95deg, #01cfa6 25.7%, #007e65 100%);
  --color-toggle-circle-bg: linear-gradient(144.73deg, #e3e3e3 15.19%, #ffffff 56.91%);
  --color-toggle-circle-strk: linear-gradient(90deg, #ffffff -20.71%, rgba(255, 255, 255, 0) 120.71%);
  --shadow-toggle: inset -1px 1px 1px rgba(0, 0, 0, 0.18);
  --shadow-toggle-active: inset 1px 1px 1px rgba(0, 0, 0, 0.18);
  --shadow-toggle-circle: 1px 1px 1px rgba(0, 0, 0, 0.18);
  --shadow-toggle-circle-active: -1px 1px 1px rgba(0, 0, 0, 0.18);

  // Controls checkbox
  --color-checkbox-bg: transparent;
  --color-checkbox-bg-active: #{$teal};
  --color-checkbox-strk: #{$dark-gray-4};
  --color-checkbox-txt: #{$dark-gray-5};
  --color-checkbox-point: #{$white};
  --border-checkbox: 1px solid var(--color-checkbox-strk);
  --shadow-checkbox: inset -1px 1px 2px rgba(13, 16, 22, 0.4);
  --shadow-checkbox-icon: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.18));
  --shadow-checkbox-active: inset 1px 1px 2px rgba(13, 16, 22, 0.4);

  // Controls radiobutton
  --color-radiobutton-bg: transparent;
  --color-radiobutton-bg-active: #{$teal};
  --color-radiobutton-strk: #{$dark-gray-4};
  --color-radiobutton-circle-bg: linear-gradient(144.73deg, #e3e3e3 15.19%, #ffffff 56.91%);
  --color-radiobutton-circle-bg-active: linear-gradient(141.95deg, #01cfa6 25.7%, #007e65 100%);
  --color-radiobutton-circle-strk: linear-gradient(90deg, #ffffff -20.71%, rgba(255, 255, 255, 0) 120.71%);

  // Controls shadows
  --shadow-controls-inner-right: -1px 1px 2px 0px #0d101666 inset;
  --shadow-controls-inner-left: 1px 1px 2px 0px #0d101666 inset;
  --shadow-controls-to-right: 1px 1px 1px 0px #0000002e;

  // Inputs
  --color-input-border-active: #{$dark-gray-4};
  --color-input-border-focus: #{$dark-gray-5};
  --color-input-border-uneditable: #{$dark-gray-3x};
  --color-input-bg-label-1: #{$dark-gray-1};
  --color-input-bg-label-2: #{$dark-gray-1x};
  --color-input-bg-label-3: #{$dark-gray-1};
  --color-input-txt-label: #{$dark-gray-5};
  --color-input-txt-label-focus: #{$dark-gray-5};
  --color-input-txt-placeholder: #{$dark-gray-4};
  --color-input-txt-filled: #{$white};
  --color-input-hint-txt: #{$dark-gray-5};
  --color-input-hint-icon: #{$teal};
  --color-input-hint-bg: #{$dark-gray-1x};
  --color-input-list-bg: #{$dark-gray-3};
  --color-input-list-bg-hover: #{$dark-gray-2};
  --color-input-list-bg-active: #{$dark-gray-2};
  --color-input-list-txt: #{$dark-gray-5};
  --color-input-list-txt-hover: #{$dark-gray-5};
  --color-input-list-txt-active: #{$white};
  --color-input-list-txt-disabled: #{$dark-gray-4};
  --color-input-list-icon: #{$dark-gray-4};
  --color-input-list-icon-hover: #{$dark-gray-5};

  // Popovers
  --popover-shadow: 1px 1px 3px 0px rgba(39, 43, 53, 0.15);

  //POP-UPS
  --color-pop-up-bg-header: #{$dark-gray-3};
  --color-pop-up-bg-body: #{$dark-gray-2};
  --color-pop-up-line: #{$dark-gray-1x};
  --color-pop-up-bg-toast: #{$dark-gray-3};
  --color-pop-up-txt-header: #{$white};
  --color-pop-up-txt-body: #{$dark-gray-7};
  --color-pop-up-ic-close: #{$dark-gray-5};
  --color-pop-up-overlay-darken: rgba(13, 16, 22, 0.7);

  // Product
  --color-product-color-selected: #{$white};

  // Cart
  --color-cart-form-tab: #{$dark-gray-4};

  // Admin Table
  --color-table-cell-header-bg: #{$dark-gray-2};
  --color-table-cell-odd-bg: rgba(27, 31, 40, 0.75);
  --color-table-cell-bg: rgba(27, 31, 40, 0.5);
  --color-table-cell-dialog-odd-bg: #{$dark-gray-1x};
  --color-table-cell-dialog-bg: #{$dark-gray-1};

  // Statuses
  --color-status-new: #{$blue};
  --color-status-inProgress: #{$blue};
  --color-status-confirmed: #{$yellow};
  --color-status-shipping: #{$yellow};
  --color-status-delivered: #{$yellow};
  --color-status-inReview: #{$yellow};
  --color-status-completed: #{$teal};
  --color-status-canceled: #{$red};
  --color-status-rejected: #{$purple};

  // User statuses
  --color-status-probation: #{$yellow};
  --color-status-active: #{$teal};
  --color-status-offboarding: #{$red};

  // Quest progress gradient colors
  --color-quest-progress-default: #{$dark-gray-1};
  --color-quest-in-progress-0: #{$blue};
  --color-quest-in-progress-1: #75ffff;
  --color-quest-in-review-0: #{$yellow};
  --color-quest-in-review-1: #ff360a;
  --color-quest-rejected-0: #{$purple};
  --color-quest-rejected-1: #ff003d;
  --color-quest-completed: #{$teal};
  --color-quest-canceled: #{$red};

  // Avatar
  --color-avatar-border: #{$gray-7};
  --color-avatar-bg: #{$dark-gray-3};
  --color-avatar-text: #{$white};
  --color-avatar-colorful-text: #{$white};
  --color-avatar-colorful-border: #{$gray-7};
  --brightness-avatar-colorful-bg: 40%;

  // Checkbox
  --color-checkbox-border: #{$dark-gray-4};
  --color-checkbox-checked-bg: #{$teal};

  // Blur background
  --blur-bg-color: rgba(13, 16, 22, 0.75);

  // Birthday calendar
  --color-day-date: #{$gray-7};
  --color-day-month: #{$dark-gray-7};
  --color-user-card-bg: #{$dark-gray-3};
  --color-user-card-shadow: none;
  --color-user-card-border-0: linear-gradient(139.58deg, rgba(121, 182, 149, 1) 0%, rgba(121, 182, 149, 0) 100%);
  --color-user-card-border-1: linear-gradient(139.58deg, rgba(137, 0, 221, 1) 0%, rgba(137, 0, 221, 0) 100%);
  --color-user-card-border-2: linear-gradient(139.58deg, rgba(44, 153, 255, 1) 0%, rgba(44, 153, 255, 0) 100%);
  --color-user-card-border-3: linear-gradient(139.58deg, rgba(211, 167, 122, 1) 0%, rgba(211, 167, 122, 0) 100%);

  // Timer
  --color-timer-number: #{$gray-7};
  --color-timer-text: #{$dark-gray-4};
  --color-timer-expired-number: #{$dark-gray-4};
  --color-timer-expired-text: #{$dark-gray-3};

  // Org tree
  --color-orgtree-header-bg: rgba(13, 16, 22, 0.85);
  --color-orgtree-link: #{$dark-gray-5};
  --color-orgtree-position: #{$white};
  --color-orgtree-position-hover: #{$teal};
  --color-orgtree-position-bg: #{$dark-gray-1};
  --color-orgtree-avatar-border: var(--color-avatar-colorful-border);
  --color-orgtree-avatar-border-hover: #{$teal};
  --color-orgtree-avatar-text: var(--color-avatar-colorful-text);
  --color-orgtree-details-header-bg: #{$dark-gray-3};
  --color-orgtree-details-body-bg: #{$dark-gray-2};
  --color-orgtree-popover-bg: #{$dark-gray-3};

  // Texture
  --texture-url: url("/assets/images/texture-bg-dark.svg");

  color: $white;
  background: var(--color-bg-1);
}
