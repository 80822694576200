.pu-uploader-file,
.pu-uploader-image {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-width: rem(60px);
  border-radius: $border-radius-1;
  cursor: pointer;
  background-color: var(--color-bg-4);
  color: var(--color-txt-icon-1);
}

.pu-uploader-file {
  height: rem(48px);
  padding: rem(10px) $spacing-4;
  gap: $spacing-2;

  &__name {
    @include font-regular-14;
    color: var(--color-txt-icon-1);
  }

  &__remove {
    visibility: hidden;
  }

  &:hover {
    background-color: var(--color-bg-4x);

    .pu-uploader-file__remove {
      visibility: visible;
    }
  }
}

.pu-uploader-image {
  height: rem(92px);
  padding: rem(20px) $spacing-4;

  &__img {
    height: 100%;
  }

  &__remove {
    z-index: 1;
    visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &:hover {
    background-color: var(--color-bg-4x);

    .pu-uploader-image__img {
      filter: brightness(0.3);
    }

    .pu-uploader-image__remove {
      visibility: visible;
    }
  }
}
