@import "layout";

// uc browser hide cdk overlays as its ads :D
div.cdk-overlay-container > div.cdk-global-overlay-wrapper > div[id*="cdk-overlay-"].cdk-overlay-pane {
  display: flex !important;
}

// Overlay backdrop styling
.cdk-overlay-backdrop.pu-overlay-backdrop {
  background-color: var(--color-pop-up-overlay-darken);
}

// Overlay panel styling
.pu-overlay-panel {
  display: flex;
  flex-direction: column;
  cursor: auto;
  height: 100%;
  justify-content: center;

  &_full-width {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &_full-screen {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &_navbar-menu,
  &_pwa-setup {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
  }
}
