@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

@font-face {
  font-family: "Pinup-Bold";
  src: url("/assets/fonts/Pinup-Bold/Pinup-Bold.eot");
  src: url("/assets/fonts/Pinup-Bold/Pinup-Bold.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/Pinup-Bold/Pinup-Bold.woff2") format("woff2"),
    url("/assets/fonts/Pinup-Bold/Pinup-Bold.woff") format("woff"),
    url("/assets/fonts/Pinup-Bold/Pinup-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}