// -------------------------------------
// Transition animation
// -------------------------------------
// usage:
// @import "@ui/styles/animation"
//
// transition: $transition-1
//
// -------------------------------------

$transition-0: 0s;
$transition-1: .2s ease-in-out;
$transition-2: .3s linear;
$transition-3: .6s;
$transition-4: .2s linear;
$transition-delay-1: .2s;

@mixin skeleton-animation() {
  background-color: var(--color-bg-3);

  &:before {
    position: absolute;
    content: "";
    height: 100%;
    width: 90%;
    animation: shine 1.5s linear infinite;
    transform: skew(150deg) translateZ(0);
    background-image: var(--gradients-skeleton-blur);
  }
}

@keyframes shine {
  0% {
    left: -150%;
  }

  100% {
    left: 150%;
  }
}

@keyframes live-flashing {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}
