@import "utilities";

@mixin size-12 {
  height: 12px;
  width: 12px;
}

@mixin size-16 {
  height: 16px;
  width: 16px;
}

@mixin size-20 {
  height: 20px;
  width: 20px;
}

@mixin size-24 {
  height: 24px;
  width: 24px;
}

@mixin size-32 {
  height: 32px;
  width: 32px;
}

@mixin size-36 {
  height: 36px;
  width: 36px;
}

@mixin size-48 {
  height: 48px;
  width: 48px;
}

@mixin size-96 {
  height: 96px;
  width: 96px;
}

.ui-icon {
  display: flex;
  align-items: center;
  justify-content: center;

  &-svg {
    &.size-12 {
      @include size-12;
    }

    &.size-16 {
      @include size-16;
    }

    &.size-20 {
      @include size-20;
    }

    &.size-24 {
      @include size-24;
    }

    &.size-32 {
      @include size-32;
    }

    &.size-36 {
      @include size-36;
    }

    &.size-48 {
      @include size-48;
    }

    &.size-96 {
      @include size-96;
    }
  }
}

@include breakpoint(md) {
  .md\:ui-icon {
    &_m .ui-icon-svg {
      @include size-24();
    }

    &_l .ui-icon-svg {
      @include size-32();
    }
  }
}
