// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

@include mat.core();

// App dark color palette
$app-primary-dark: mat.define-palette(mat.$indigo-palette);
$app-accent-dark: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$app-warn-dark: mat.define-palette(mat.$red-palette);

$app-theme-dark: mat.define-light-theme((
  color: (
    primary: $app-primary-dark,
    accent: $app-accent-dark,
    warn: $app-warn-dark
  ),
  typography: mat.define-typography-config(),
  density: 0,
));

@include mat.core-theme($app-theme-dark);
// @include mat.table-theme($app-theme-dark);
// @include mat.slide-toggle-theme($app-theme-dark);

// App light color palette
$app-primary-light: mat.define-palette(mat.$indigo-palette);
$app-accent-light: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$app-warn-light: mat.define-palette(mat.$red-palette);

$app-theme-light: mat.define-light-theme((
  color: (
    primary: $app-primary-light,
    accent: $app-accent-light,
    warn: $app-warn-light
  ),
  typography: mat.define-typography-config(),
  density: 0,
));

@media (prefers-color-scheme: light) {
 @include mat.core-color($app-theme-light);
 @include mat.slide-toggle-color($app-theme-light);
}

