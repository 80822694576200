@import "typography";

.mat-mdc-slide-toggle {
  .mdc-switch {
    .mdc-switch__track {
      height: 16px !important;
      border-radius: 10px !important;

      &::after {
        background: var(--color-slide-toggle-track-active) !important;
      }

      &::before {
        background: var(--color-slide-toggle-track) !important;
      }

      &::after,
      &::before {
        border: none !important;
        box-shadow: var(--shadow-slide-toggle-track);
      }
    }

    .mdc-switch__handle {
      &::after {
        background: var(--gradient-slide-toggle-handle) !important;
        border: 2px solid var(--color-slide-toggle-handle-bg) !important;
        height: calc(100% - 2px);
        width: calc(100% - 2px);
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      &::before {
        background: var(--color-slide-toggle-handle-border-active) !important;
      }
    }

    &.mdc-switch--unselected {
      .mdc-switch__handle::before {
        background: var(--color-slide-toggle-handle-border) !important;
      }
    }
  }

  label {
    @include font-regular-14;
    color: var(--color-txt-icon-3);
  }

  .mdc-switch__icons,
  .mdc-switch__ripple {
    display: none;
  }
}
