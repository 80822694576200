@import "@ui/styles/utilities";
@import "@ui/styles/icons";

:host {
  position: relative;
}

.nav-item {
  @include button-reset();
  background-color: transparent;

  display: grid;
  text-align: center;
  grid-gap: 2px;
  position: relative;
  text-decoration: none;
  padding: $spacing-3 0 $spacing-4;
  color: var(--color-txt-icon-3);
  @include font-regular-12;

  @include breakpoint(sm) {
    grid-gap: 0;
  }

  @include breakpoint(md) {
    @include font-regular-14;
  }

  &:active,
  &:hover,
  &:focus {
    color: var(--color-txt-icon-1);

    .nav-item__icon {
      color: var(--color-txt-icon-1);
    }
  }

  &__btn {
    @include button-reset();
    background-color: transparent;

    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: $spacing-3 0 $spacing-4;
    @include font-regular-12;
    color: var(--color-txt-icon-3);
    white-space: nowrap;
    gap: 2px;

    @include breakpoint(sm) {
      gap: 0;
    }

    &:active,
    &:hover,
    &:focus {
      color: var(--color-txt-icon-1);

      .nav-item__icon {
        color: var(--color-txt-icon-1);
      }
    }

    @include breakpoint(md) {
      @include font-regular-14;
    }
  }

  &__icon {
    color: var(--color-txt-icon-3);
  }

  &_active {
    color: var(--color-txt-icon-1);

    .nav-item {
      &__icon {
        color: var(--color-txt-icon-1);
      }
    }
  }
}

.pu-sidebar__nav {
  a.nav-item,
  button.nav-item {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 12px;
    padding: $spacing-2 0;
    @include font-regular-14;

    @include breakpoint(xl) {
      @include font-regular-16;
    }

    .ui-icon-svg {
      @include size-24;
    }
  }
}

.pu-menu__nav,
.pu-support__nav {
  a.nav-item,
  button.nav-item {
    padding: $spacing-2 0;
    @include font-medium-14;

    @include breakpoint(sm) {
      padding: $spacing-3 0;
      @include font-medium-18;
    }

    @include breakpoint(md) {
      @include font-medium-20;
    }
  }
}
