@import "reset";
@import "fonts";
@import "overlay";
@import "icons";
@import "slider";
@import "forms";
@import "table";
@import "uploader";
@import "slide-toggle";
@import "paginator";
@import "datepicker";
@import "breadcrumbs";
@import "checkbox";
@import "back-btn";

@import "../button/button";
@import "../nav-item/nav-item";

html, body {
  height: 100%;
}

body {
  @include font-family;
  font-size: $base-font-size;

  &.pu-touch {
    cursor: pointer;
  }
}

[hidden] {
  display: none !important;
}

* {
  scrollbar-width: thin;
}

*::-webkit-scrollbar {
  width: $spacing-1;
  height: $spacing-1;
}

*::-webkit-scrollbar-track {
  background-color: var(--color-bg-2);
}

*::-webkit-scrollbar-thumb {
  border-radius: 0.75em;
  background-color: var(--color-bg-5);
}

.pu-root {
  display: block;
  width: 100%;
  height: 100%;

  // Preventing layout shift
  // @include breakpoint(lg) {
  //   overflow-y: scroll;

  //   @supports (scrollbar-gutter: stable) {
  //     overflow: auto;
  //     scrollbar-gutter: stable;
  //   }
  // }
}

@mixin container {
  @include padding(0px, s3, 68px, s3);

  @include breakpoint(md) {
    @include padding(0px, s4, 78px, s4);
  }

  @include breakpoint(lg) {
    @include padding(0px, s6, 0px, s6);
  }
}

// Container general
.container {
  @include container;
}

// Container from md only
@include breakpoint(md) {
  .md\:container {
    @include container;
  }
}

// Container xl only
@include breakpoint(xl) {
  .xl\:container {
    @include container;
  }
}

.pu-scroll-shadow,
.pu-shadow-dialog {
  display: flex;
  position: absolute;
  z-index: -2;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--gradient-shadow);
  transition: bottom $transition-3;
}

.pu-scroll-shadow {
  height: rem(60px);

  @include breakpoint(lg) {
    height: rem(80px);
  }

  &_visible {
    bottom: -60px;

    @include breakpoint(lg) {
      bottom: -80px;
    }
  }
}

.pu-shadow-dialog {
  height: rem(20px);

  &_visible {
    bottom: -20px;
  }
}

.pu-admin-top-bar {
  position: sticky;
  z-index: 1;
  top: 64px;
  padding-top: $spacing-4;
  margin-top: -$spacing-4;
  z-index: z-index(under-header);
  background-color: var(--color-bg-1);

  @include breakpoint(md) {
    padding-top: $spacing-5;
    margin-top: -$spacing-5;
    top: 230px;
  }

  @include breakpoint(lg) {
    top: 174px;
  }

  &::after {
    content: '';
    position: absolute;
    height: $spacing-4;
    left: 0;
    bottom: -$spacing-4;
    width: 100%;
    background: var(--gradient-shadow);

    @include breakpoint(md) {
      bottom: -$spacing-5;
      height: $spacing-5;
    }
  }
}
