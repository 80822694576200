@import "geometry";

@mixin hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  visibility: visible;
  white-space: nowrap;
}

@mixin button-reset($width: true) {
  @include _reset;
  @include _font-smoothing;
  display: inline-block;
  text-decoration: none;
  background-color: none;
  appearance: none;
  border: 0;
  padding: 0;
  letter-spacing: 0;
  cursor: pointer;

  @if ($width == true) {
    width: 100%;
  }

  &::-moz-focus-inner {
    border: 0;
  }
}

@mixin text-ellipsis {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@mixin text-line-clamp($count: 3) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $count;
  overflow: hidden;
}

@mixin rotate($deg, $speed, $origin: center) {
  transform: rotate($deg);
  transition: transform $speed;
  transform-origin: $origin;
}

@mixin _reset {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;

  & > *,
  & > *:before,
  & > *:after {
    box-sizing: inherit;
  }
}

@mixin gradient-border($borderWidth, $gradient) {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  border: $borderWidth solid transparent;
  background: var($gradient) border-box;
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
}

@mixin old-scss {
  body.pu-old-scss & {
    @content;
  }
}

@mixin old-scss-encapsulate {
  :host-context(body.pu-old-scss) {
    @content;
  }
}

// border gradient for old devices
@mixin gradient-border-old($padding: 1px) {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: $border-radius-1;
  clip-path: polygon(
      0% 0%,
      0% 100%,
      $padding 100%,
      $padding $padding,
      calc(100% - #{$padding}) $padding,
      calc(100% - #{$padding}) calc(100% - #{$padding}),
      0 calc(100% - #{$padding}),
      0 100%,
      100% 100%,
      100% 0%
  );
  -webkit-clip-path: polygon(
      0% 0%,
      0% 100%,
      $padding 100%,
      $padding $padding,
      calc(100% - #{$padding}) $padding,
      calc(100% - #{$padding}) calc(100% - #{$padding}),
      0 calc(100% - #{$padding}),
      0 100%,
      100% 100%,
      100% 0%
  );
}

// Only applied to bold weight text
@mixin _font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin horizontal-divider($left: 0, $right: 0, $color: var(--gradient-divider-horiz)) {
  content: "";
  position: absolute;
  left: $left;
  right: $right;
  height: 1px;
  background: $color;
  width: 100%;
}

@mixin vertical-divider($top: 0, $bottom: 0, $height: 100%, $color: var(--gradient-divider-vert)) {
  content: "";
  position: absolute;
  top: $top;
  bottom: $bottom;
  width: 1px;
  background: $color;
  height: $height;
}

@mixin scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
    width: 0;
  }
}

@mixin input-autofill-rewrite($background: var(--color-bg-1), $color: var(--color-input-txt-filled)) {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1000px $background inset !important;
    -webkit-text-fill-color: $color !important;
  }
}

@mixin circle-before-hint {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: $spacing-1;
  height: $spacing-1;
  border-radius: 50%;
}

@mixin pseudo-triangle($color: var(--color-headers-bg-tertiary)) {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: $spacing-5;
  background: $color;
  right: calc(-#{$spacing-5} + 1px);
  clip-path: polygon(0 0, 100% 0, 20% 100%, 0 100%);
}

@mixin pseudo-slant($color: var(--color-headers-bg-tertiary)) {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: $spacing-5;
  background: $color;
  right: -$spacing-6;
  clip-path: polygon(80% 0, 100% 0, 20% 100%, 0 100%);
}

@mixin live-event-circle {
  width: 20px;
  height: 20px;
  position: relative;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--color-event-card-txt-icon-brand-primary);
    border-radius: 100%;
  }

  &:before {
    opacity: 0.3;
    width: 14px;
    height: 14px;
    animation: live-pulse 2s ease-out infinite 0.5s;
    // TODO: Check the performance, remove the blur if it lags
    filter: blur(2.5px);
  }

  &:after {
    width: 5px;
    height: 5px;
  }

  @keyframes live-pulse {
    0% {
      transform: translate(-50%, -50%) scale(0);
      opacity: 0;
    }

    50% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 0.3;
    }

    100% {
      opacity: 0;
    }
  }
}

@mixin custom-underline {
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 4px;
}

@mixin status-border {

}
