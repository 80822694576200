@import "@ui/styles/utilities";

@mixin ui-button-s {
  gap: $spacing-2;
  @include font-medium-12;
  @include padding(5px, s4, 5px, s4);

  .ui-icon-svg.m {
    width: 12px;
    height: 12px;
  }

  // &.ui-button_special-go-top {
  //   padding: $spacing-2;

  //   &:hover:not(:disabled),
  //   &:active,
  //   &.ui-button_pressed {
  //     padding-bottom: 18px;
  //   }

  //   .ui-icon-svg.m {
  //     width: 24px;
  //     height: 24px;
  //   }
  // }
}

@mixin ui-button-m {
  gap: $spacing-2;
  @include font-medium-14;
  @include padding(7px, s5, 7px, s5);

  .ui-icon-svg.m {
    width: 20px;
    height: 20px;
  }

  &.ui-button_only-icon {
    padding: 6px;

    .ui-icon-svg.m {
      width: 24px;
      height: 24px;
    }

    // Special case when the text appears only on hover
    // &.ui-button_text-hover {
    //   &:hover {
    //     @include padding(s2, s4, s2, s4);
    //   }
    // }
  }
}

@mixin ui-button-l {
  gap: $spacing-2;
  @include font-medium-16;
  @include padding(9px, s5, 9px, s5);

  .ui-icon-svg.m {
    width: 24px;
    height: 24px;
  }

  &.ui-button_only-icon {
    padding: 8px;

    &.ui-button_secondary {
      padding: $spacing-2 $spacing-5
    }

    &.ui-button_tertiary {
      padding: $spacing-1;
    }
  }
}

.ui-button {
  @include button-reset(false);
  @include ui-button-s;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: $border-radius-1;
  transition: $transition-1;
  color: var(--color-btn-txt);

  &_s {
    @include ui-button-s;
  }

  &_m {
    @include ui-button-m;
  }

  &_l {
    @include ui-button-l;
  }

  &:active,
  &.ui-button_pressed {
    color: var(--color-btn-txt-pressed);
    background-color: var(--color-btn-bg-pressed);
  }

  &_primary {
    background-color: var(--color-btn-primary-bg);
  }

  &_primary:not(&_pressed):not(:disabled):hover {
    background-color: var(--color-btn-primary-bg-hover);
  }

  @media (pointer: coarse) {
    &_primary:not(&_pressed):not(:active):not(:disabled):hover {
      background-color: var(--color-btn-primary-bg);
    }
  }

  &_secondary {
    background-color: var(--color-btn-secondary-bg);
  }

  &_secondary:not(&_pressed):not(:disabled):hover {
    background-color: var(--color-btn-secondary-bg-hover);
  }

  @media (pointer: coarse) {
    &_secondary:not(&_pressed):not(:active):not(:disabled):hover {
      background-color: var(--color-btn-secondary-bg);
    }
  }

  &_tertiary {
    background-color: var(--color-btn-tertiary-bg);
  }

  &_tertiary:not(&_pressed):not(:disabled):hover {
    background-color: var(--color-btn-tertiary-bg-hover);
  }

  @media (pointer: coarse) {
    &_tertiary:not(&_pressed):not(:active):not(:disabled):hover {
      background-color: var(--color-btn-tertiary-bg);
    }
  }

  &_quaternary {
    align-self: center;
    padding: 0;
    background-color: transparent;
    color: var(--color-btn-quaternary);
    transition: font-size $transition-1;
    @include custom-underline;

    &:active,
    &.ui-button_pressed {
      text-decoration: none;
      background: none;
      color: var(--color-btn-quaternary-pressed);
    }

    &:not(.ui-button_pressed):not(:disabled):hover {
      color: var(--color-btn-quaternary-hover);
    }

    @media (pointer: coarse) {
      &:not(.ui-button_pressed):not(:active):not(:disabled):hover {
        color: var(--color-btn-quaternary);
      }
    }
  }

  &_gradient {
    position: relative;
    min-width: rem(100px);
    padding: $spacing-2 $spacing-3;
    @include font-medium-12;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
    background-color: transparent;
    border: 1px solid var(--color-sub-category-tab-strk);
    color: var(--color-sub-category-txt);

    @include breakpoint(lg) {
      @include padding(5px, s4, 5px, s4);
    }

    &:active,
    &.ui-button_pressed {
      border-color: transparent;
      background: var(--color-sub-category-tab-bg);
      color: var(--color-sub-category-txt-active);

      &::before {
        @include gradient-border(1px, --color-sub-category-tab-strk-active);
      }
    }

    &:not(.ui-button_pressed):not(:disabled):hover {
      border-color: transparent;
      color: var(--color-sub-category-txt-hover);

      &::before {
        @include gradient-border(1px, --color-sub-category-tab-strk-hover);
      }
    }

    @media (pointer: coarse) {
      &:not(.ui-button_pressed):not(:active):not(:disabled):hover {
        border: 1px solid var(--color-sub-category-tab-strk);
        color: var(--color-sub-category-txt);

        &::before {
          background: transparent;
        }
      }
    }
  }

  &:disabled,
  &_disabled {
    pointer-events: none;
    opacity: 0.3;
  }
}

@include breakpoint(md) {
  .md\:ui-button {
    //&_s {
    //  @include ui-button-s;
    //}

    &_m {
      @include ui-button-m;
    }

    &_l {
      @include ui-button-l;
    }
  }
}

@include breakpoint(lg) {
  .lg\:ui-button {
    &_s {
      @include ui-button-s;
    }

    &_m {
      @include ui-button-m;
    }

    &_l {
      @include ui-button-l;
    }
  }
}

@include breakpoint(xl) {
  .xl\:ui-button {
    &_s {
      @include ui-button-s;
    }

    &_m {
      @include ui-button-m;
    }

    &_l {
      @include ui-button-l;
    }
  }
}
