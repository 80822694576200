.pu-breadcrumbs {
  display: flex;
  gap: $spacing-1;
  font-family: $font-family-roboto;
  @include font-regular-12;

  &__item {
    text-decoration: none;
    cursor: pointer;
    color: var(--color-txt-icon-2);

    &:hover {
      color: var(--color-txt-icon-1);
    }
  }

  &__separator {
    color: var(--color-txt-icon-2);
  }

  &__tail {
    max-width: 300px;
    @include text-ellipsis;
    color: var(--color-txt-icon-3);
  }
}
