@import "typography";

// -------------------------------------
// Border radius
// -------------------------------------
// usage:
// @import "@ui/styles/geometry"
//
// border-radius: $border-radius-1
//
// -------------------------------------

$border-radius-0: rem(2px);
$border-radius-1: rem(4px);
$border-radius-2: rem(8px);
$border-radius-3: rem(16px);
