@import "@ui/styles/utilities";

.mat-calendar {
  width: 100%;
  min-width: 250px;
  max-width: 340px;
  padding: $spacing-4;
  border: 1px solid var(--color-bg-3);
  box-shadow: var(--shadow-calendar-dropdown);
  background: var(--color-bg-1);
  display: block;

  .mat-calendar-header {
    padding: 0 $spacing-3;

    .mat-calendar-spacer {
      display: none;
    }

    .mat-calendar-previous-button {
      order: -1;
    }

    .mat-calendar-next-button,
    .mat-calendar-previous-button {
      background-color: none;
      color: var(--color-txt-icon-3);

      &.mat-button-disabled {
        opacity: .3;
      }
    }

    .mat-calendar-period-button {
      @include font-medium-16;
      @include button-reset(false);

      background-color: none;
      pointer-events: none;
      flex: 1;
      color: var(--color-txt-icon-3);

      .mat-calendar-arrow {
        display: none;
      }
    }
  }

  .mat-calendar-controls {
    margin-top: 0;
    margin-bottom: $spacing-1;
  }

  .mat-calendar-content {
    padding: 0;
  }

  .mat-calendar-table-header {
    @include font-medium-10;

    color: var(--color-txt-icon-4);

    th {
      padding-bottom: $spacing-4;
    }

    .mat-calendar-table-header-divider {
      display: none;
    }
  }

  .mat-calendar-body {
    tr[aria-hidden] {
      display: none;
    }

    .mat-calendar-body-label {
      opacity: 0;
    }
  }

  .mat-calendar-body-cell-content {
    @include font-regular-16;

    border: none;
    color: var(--color-txt-icon-3);

    &:before {
      display: none;
    }
  }

  .mat-calendar-body-disabled {
    .mat-calendar-body-cell-content {
      color: var(--color-txt-icon-4);
    }
  }

  .mat-calendar-body-selected + .mat-calendar-body-cell-preview,
  .mat-calendar-body-in-range .mat-calendar-body-cell-preview {
    background-color: var(--color-brand-secondary-light);
  }

  .mat-calendar-body-selected + .mat-calendar-body-cell-preview {
    border-radius: $border-radius-0;
  }

  .mat-calendar-body-range-start .mat-calendar-body-cell-preview {
    border-radius: $border-radius-0 0 0 $border-radius-0;
  }

  .mat-calendar-body-range-end .mat-calendar-body-cell-preview {
    border-radius: 0 $border-radius-0 $border-radius-0 0;
  }
}
