.pu-swiper-autosize {
  .swiper-slide {
    width: auto;
  }
}

.pu-swiper-equal-height {
  .swiper-slide {
    height: auto;
  }
}

.swiper-wrapper {
  display: flex;
  justify-content: flex-start;
}

.pu-swiper-centered {
  .swiper-wrapper {
    justify-content: center;
    transform: translate3d(0px, 0, 0) !important;
  }

  .swiper-slide:last-child {
    margin-right: 0 !important;
  }
}

// fix for first and last slide according to design
.pu-swiper-without-gaps {
  .swiper-slide {
    &:first-child {
      margin-left: -#{$spacing-2};

      @include breakpoint(md) {
        margin-left: -#{$spacing-3};
      }
    }

    &:last-child {
      margin-right: -#{$spacing-2};

      @include breakpoint(md) {
        margin-right: -#{$spacing-3};
      }
    }
  }
}

// swiper button style
.swiper-button-disabled,
.swiper-button-lock {
  display: none !important;
}

.swiper-pagination {
  position: absolute;
  z-index: 2;
  display: flex;
  bottom: $spacing-3;
  cursor: pointer;
}

.swiper-pagination-bullet {
  @include margin(0px, 2px, 0px, 2px);

  width: $spacing-3;
  height: 2px;
  background: var(--color-banner-txt-icon);
  opacity: 0.4;
  border-radius: 2px;
}

.swiper-pagination-bullet-active {
  width: $spacing-5;
  opacity: initial;
}

.ui-sub-category-tabs {
  .ui-sub-category-tabs__slide {
    &:not(:last-child) {
      margin-right: $spacing-3;

      @include breakpoint(md) {
        margin-right: $spacing-4;
      }
    }
  }
}
