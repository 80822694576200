@import '@ui/styles/utilities';

/*
 * froala_editor v4 (https://www.froala.com/wysiwyg-editor)
 */

.fr-box {
  ul {
    margin-left: 20px;
    list-style-type: disc;
  }

  ol {
    margin-left: 20px;
    list-style-type: decimal;
  }

  b, strong {
    font-weight: bold;
  }

  i, em {
    font-style: italic;
  }

  &.fr-basic {
    border-radius: 5px;

    .fr-toolbar {
      background-color: transparent;
      border-radius: 5px 5px 0 0;
      border-bottom: 0;
    }

    .fr-wrapper {
      background-color: transparent;
      border: 1px solid #CCCCCC;
    }

    .fr-second-toolbar {
      border-top: 0;
      background-color: transparent;
      border-radius: 0 0 5px 5px;
    }

    &.fr-top .fr-wrapper {
      border-top: 1px solid #CCCCCC;
    }
  }

  &.fr-fullscreen {
    .fr-toolbar {
      border-radius: 0;
      border-bottom: 0;
      background-color: var(--color-input-list-bg);
    }

    .fr-wrapper {
      background-color: var(--color-input-list-bg);
      border: 1px solid #CCCCCC;
    }

    .fr-second-toolbar {
      border-radius: 0;
      border-top: 0;
      background-color: var(--color-input-list-bg);
    }
  }
}

:root.pu-dark-theme {
  .fr-box.fr-basic .fr-element {
    color: var(--color-input-txt-filled);
  }

  .fr-box a.fr-floating-btn {
    -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    background: #353535;
    color: #0098f7;
  }

  .fr-box a.fr-floating-btn svg {
    fill: #0098f7;
  }

  .fr-box a.fr-floating-btn:hover {
    background: #424242;
  }

  .fr-box a.fr-floating-btn:hover svg {
    fill: #0098f7;
  }

  .fr-wrapper .fr-placeholder {
    color: #AAA;
  }

  .fr-wrapper ::-moz-selection {
    background: rgba(181, 214, 253, 0.8);
    color: #000;
  }

  .fr-wrapper ::selection {
    background: rgba(181, 214, 253, 0.8);
    color: #000;
  }

  .fr-box.fr-basic .fr-wrapper {
    background: transparent;
    border: 1px solid var(--color-input-border-active);
    border-bottom-color: var(--color-input-border-active);
  }

  @media (min-width: 992px) {
    .fr-box.fr-document .fr-wrapper {
      background: #efefef;
    }
    .fr-box.fr-document .fr-wrapper .fr-element {
      background: #FFF;
    }
    .fr-box.fr-document .fr-wrapper .fr-element hr {
      background: #efefef;
    }
  }

  .fr-box .fr-counter {
    color: #999999;
  }

  textarea.fr-code {
    background: #FFF;
    color: #000;
  }

  .fr-box.fr-inline .fr-command.fr-btn.html-switch {
    background: #FFF;
    color: #FFF;
  }

  .fr-box.fr-inline .fr-command.fr-btn.html-switch.fr-desktop:hover {
    background: #424242;
  }

  .fr-popup .fr-color-set > span > i path, .fr-popup .fr-color-set > span > svg path {
    fill: #FFF;
  }

  .fr-popup .fr-color-set > span .fr-selected-color {
    color: #FFF;
  }

  .fr-popup .fr-color-set > span:hover, .fr-popup .fr-color-set > span:focus {
    outline: 1px solid #FFF;
  }

  .fr-drag-helper {
    background: #0098f7;
  }

  .fr-popup .fr-file-upload-layer {
    border: dashed 2px gray;
  }

  .fr-popup .fr-file-upload-layer:hover {
    background: #424242;
  }

  .fr-popup .fr-file-upload-layer.fr-drop {
    background: #424242;
    border-color: #0098f7;
  }

  .fr-popup .fr-file-progress-bar-layer > div.fr-loader {
    background: #b3e0fd;
  }

  .fr-popup .fr-file-progress-bar-layer > div.fr-loader span {
    background: #0098f7;
  }

  .fr-image-resizer {
    border: solid 1px #0098f7;
  }

  .fr-image-resizer .fr-handler {
    background: #0098f7;
    border: solid 1px #FFF;
  }

  .fr-popup .fr-files-upload-layer {
    border: dashed 2px gray;
  }

  .fr-popup .fr-files-upload-layer:hover {
    background: #424242;
  }

  .fr-popup .fr-files-upload-layer.fr-drop {
    background: #424242;
    border-color: #0098f7;
  }

  .fr-popup .fr-files-progress-bar-layer > div.fr-loader {
    background: #b3e0fd;
  }

  .fr-popup .fr-files-progress-bar-layer > div.fr-loader span {
    background: #0098f7;
    -webkit-transition: width 0.2s ease 0s;
    -moz-transition: width 0.2s ease 0s;
    -ms-transition: width 0.2s ease 0s;
    -o-transition: width 0.2s ease 0s;
  }

  .fr-progress-bar {
    background-color: #4CAF50;
  }

  .fr-files-checkbox input:not(:checked) + span {
    border: solid 2px #999999;
  }

  .fr-files-checkbox input:not(:checked):focus + span, .fr-files-checkbox input:not(:checked):hover + span {
    border-color: #515151;
  }

  .fr-files-checkbox input:checked + span {
    background: #0098f7;
    border: solid 2px #0098f7;
  }

  .fr-files-checkbox input:checked:active + span {
    background-color: #EcF5Ff;
  }

  .fr-checkbox-disabled input:not(:checked) + span {
    border: solid 2px #999999;
  }

  .fr-checkbox-disabled input:not(:checked):active + span {
    background-color: #353535;
  }

  .fr-checkbox-disabled input:checked + span {
    background: #0098f7;
    border: solid 2px #0098f7;
  }

  .fr-checkbox-disabled input:checked:active + span {
    background-color: #EcF5Ff;
  }

  #myprogressBar {
    background-color: #4CAF50;
  }

  .fr-hovered-over-file {
    background-color: #F0F0F0;
  }

  .fr-box.fr-fullscreen .fr-wrapper {
    background-color: var(--color-input-list-bg);
  }

  .fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal table tbody tr {
    border-bottom: solid 1px #595959;
  }

  .fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal table tbody td:first-child {
    color: white;
  }

  .fr-image-resizer {
    border: solid 1px #0098f7;
  }

  .fr-image-resizer .fr-handler {
    background: #0098f7;
    border: solid 1px #FFF;
  }

  .fr-popup .fr-image-upload-layer {
    border: dashed 2px gray;
  }

  .fr-popup .fr-image-upload-layer:hover {
    background: #424242;
  }

  .fr-popup .fr-image-upload-layer.fr-drop {
    background: #424242;
    border-color: #0098f7;
  }

  .fr-popup .fr-image-progress-bar-layer > div.fr-loader {
    background: #b3e0fd;
  }

  .fr-popup .fr-image-progress-bar-layer > div.fr-loader span {
    background: #0098f7;
  }

  .fr-modal .fr-modal-head .fr-modal-tags a {
    background-color: #353535;
  }

  .fr-modal .fr-modal-head .fr-modal-tags a:focus {
    background-color: #424242;
  }

  .fr-modal .fr-modal-head .fr-modal-tags a:hover {
    background-color: #424242;
  }

  .fr-modal .fr-modal-head .fr-modal-tags a:active {
    background-color: #4f4f4f;
  }

  .fr-modal .fr-modal-head .fr-modal-tags a.fr-selected-tag {
    background-color: #4f4f4f;
    color: #0098f7;
  }

  .fr-modal .fr-modal-head .fr-modal-tags a.fr-selected-tag:focus {
    background-color: #424242;
  }

  .fr-modal .fr-modal-head .fr-modal-tags a.fr-selected-tag:hover {
    background-color: #424242;
  }

  .fr-modal .fr-modal-head .fr-modal-tags a.fr-selected-tag:active {
    background-color: #4f4f4f;
  }

  .fr-show-tags .fr-modal-more svg path {
    fill: #0098f7;
  }

  div.fr-modal-body div.fr-image-list div.fr-image-container.fr-image-deleting::after {
    background: #000;
  }

  div.fr-modal-body div.fr-image-list div.fr-image-container.fr-image-deleting::before {
    color: #FFF;
  }

  div.fr-modal-body div.fr-image-list div.fr-image-container.fr-empty {
    background: #AAA;
  }

  div.fr-modal-body div.fr-image-list div.fr-image-container .fr-delete-img {
    background: #B8312F;
    fill: #FFF;
  }

  div.fr-modal-body div.fr-image-list div.fr-image-container .fr-insert-img {
    background: #353535;
    fill: #0098f7;
  }

  .fr-desktop .fr-modal-wrapper div.fr-modal-body div.fr-image-list div.fr-image-container .fr-delete-img:hover {
    background: #bf4644;
    color: #FFF;
  }

  .fr-desktop .fr-modal-wrapper div.fr-modal-body div.fr-image-list div.fr-image-container .fr-insert-img:hover {
    background: #424242;
  }

  .fr-line-breaker {
    border-top: 1px solid #0098f7;
  }

  .fr-qi-helper a.fr-btn.fr-floating-btn {
    color: #FFF;
    background: #353535;
  }

  .fr-qi-helper a.fr-btn.fr-floating-btn svg {
    fill: #FFF;
  }

  .fr-element table td.fr-selected-cell, .fr-element table th.fr-selected-cell {
    border: 1px double #0098f7;
  }

  .fr-table-resizer div {
    border-right: 1px solid #0098f7;
  }

  .fr-popup .fr-table-size .fr-select-table-size > span > span {
    border: 1px solid #DDD;
  }

  .fr-popup .fr-table-size .fr-select-table-size > span.hover > span {
    background: rgba(0, 152, 247, 0.3);
    border: solid 1px #0098f7;
  }

  .fr-box .fr-video-resizer {
    border: solid 1px #0098f7;
  }

  .fr-box .fr-video-resizer .fr-handler {
    background: #0098f7;
    border: solid 1px #FFF;
  }

  .fr-popup .fr-video-upload-layer {
    border: dashed 2px gray;
  }

  .fr-popup .fr-video-upload-layer:hover {
    background: #424242;
  }

  .fr-popup .fr-video-upload-layer.fr-drop {
    background: #424242;
    border-color: #0098f7;
  }

  .fr-popup .fr-video-progress-bar-layer > div.fr-loader {
    background: #b3e0fd;
  }

  .fr-popup .fr-video-progress-bar-layer > div.fr-loader span {
    background: #0098f7;
  }

  .fr-view img.fr-shadow, .fr-view .fr-img-caption.fr-shadow img {
    -webkit-box-shadow: 10px 10px 5px 0px #cccccc;
    -moz-box-shadow: 10px 10px 5px 0px #cccccc;
    box-shadow: 10px 10px 5px 0px #cccccc;
  }

  .fr-view span[style~="color:"] a {
    color: inherit;
  }

  .fr-view table.fr-alternate-rows tbody tr:nth-child(2n) {
    background: lightgray;
  }

  .fr-view table td, .fr-view table th {
    border: 1px solid #DDD;
  }

  .fr-view table th {
    background: #ececec;
  }

  .fr-view table tfoot td {
    background: #ececec;
  }

  .fr-view[dir="rtl"] blockquote {
    border-right: solid 2px #5E35B1;
  }

  .fr-view[dir="rtl"] blockquote blockquote {
    border-color: #00BCD4;
  }

  .fr-view[dir="rtl"] blockquote blockquote blockquote {
    border-color: #43A047;
  }

  .fr-view blockquote {
    border-left: solid 2px #5E35B1;
    color: #5E35B1;
  }

  .fr-view blockquote blockquote {
    border-color: #00BCD4;
    color: #00BCD4;
  }

  .fr-view blockquote blockquote blockquote {
    border-color: #43A047;
    color: #43A047;
  }

  .fr-view .fr-class-highlighted {
    background-color: #ffff00;
  }

  .fr-view .fr-class-code {
    background: #353535;
  }

  .fr-box .fr-embedly-resizer {
    border: solid 1px #0098f7;
  }

  .tui-editor-cancel-btn {
    background-color: #FFF;
    border: 1px solid #AAA;
  }

  .tui-editor-save-btn {
    color: #FFF;
  }

  .sc-cm-holder > .sc-cm {
    border-top: 5px solid #222222 !important;
  }

  .sc-cm__item_dropdown:hover > a, .sc-cm a:hover {
    background-color: #424242 !important;
  }

  .sc-cm__item_active > a, .sc-cm__item_active > a:hover, .sc-cm a:active, .sc-cm a:focus {
    background-color: #424242 !important;
  }

  .sc-cm-holder > .sc-cm:before {
    background-color: #424242 !important;
  }

  .fr-tooltip {
    background: #222222;
    color: #FFF;
  }

  .fr-toolbar .fr-command.fr-btn, .fr-popup .fr-command.fr-btn, .fr-modal .fr-command.fr-btn {
    color: #FFF;
    border: 0;
  }

  .fr-toolbar .fr-command.fr-btn::-moz-focus-inner, .fr-popup .fr-command.fr-btn::-moz-focus-inner, .fr-modal .fr-command.fr-btn::-moz-focus-inner {
    border: 0;
  }

  .fr-toolbar .fr-command.fr-btn svg path, .fr-popup .fr-command.fr-btn svg path, .fr-modal .fr-command.fr-btn svg path {
    fill: #FFF;
  }

  .fr-toolbar .fr-command.fr-btn span.fr-sr-only, .fr-popup .fr-command.fr-btn span.fr-sr-only, .fr-modal .fr-command.fr-btn span.fr-sr-only {
    border: 0;
  }

  .fr-toolbar .fr-command.fr-btn.fr-btn-active-popup, .fr-popup .fr-command.fr-btn.fr-btn-active-popup, .fr-modal .fr-command.fr-btn.fr-btn-active-popup {
    background: #4f4f4f;
  }

  .fr-toolbar .fr-command.fr-btn.fr-dropdown:after, .fr-popup .fr-command.fr-btn.fr-dropdown:after, .fr-modal .fr-command.fr-btn.fr-dropdown:after {
    border-top: 4px solid #FFF;
  }

  .fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active, .fr-popup .fr-command.fr-btn.fr-dropdown.fr-active, .fr-modal .fr-command.fr-btn.fr-dropdown.fr-active {
    fill: #FFF;
    background: #4f4f4f;
  }

  .fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active:hover, .fr-popup .fr-command.fr-btn.fr-dropdown.fr-active:hover, .fr-modal .fr-command.fr-btn.fr-dropdown.fr-active:hover {
    background: #424242;
    fill: #FFF;
  }

  .fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active:hover::after, .fr-popup .fr-command.fr-btn.fr-dropdown.fr-active:hover::after, .fr-modal .fr-command.fr-btn.fr-dropdown.fr-active:hover::after {
    border-top-color: #FFF;
  }

  .fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active:after, .fr-popup .fr-command.fr-btn.fr-dropdown.fr-active:after, .fr-modal .fr-command.fr-btn.fr-dropdown.fr-active:after {
    border-top: 0;
    border-bottom: 4px solid #FFF;
  }

  .fr-toolbar .fr-command.fr-btn.fr-disabled, .fr-popup .fr-command.fr-btn.fr-disabled, .fr-modal .fr-command.fr-btn.fr-disabled {
    color: gray;
  }

  .fr-toolbar .fr-command.fr-btn.fr-disabled::after, .fr-popup .fr-command.fr-btn.fr-disabled::after, .fr-modal .fr-command.fr-btn.fr-disabled::after {
    border-top-color: gray;
  }

  .fr-toolbar .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active, .fr-toolbar .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active-tab, .fr-popup .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active, .fr-popup .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active-tab, .fr-modal .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active, .fr-modal .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active-tab {
    background: #353535;
  }

  .fr-toolbar .fr-btn-grp .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path, .fr-toolbar .fr-more-toolbar .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path, .fr-toolbar .fr-buttons:not(.fr-tabs) .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path, .fr-popup .fr-btn-grp .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path, .fr-popup .fr-more-toolbar .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path, .fr-popup .fr-buttons:not(.fr-tabs) .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path, .fr-modal .fr-btn-grp .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path, .fr-modal .fr-more-toolbar .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path, .fr-modal .fr-buttons:not(.fr-tabs) .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path {
    fill: #0098f7;
  }

  .fr-toolbar.fr-disabled .fr-btn, .fr-toolbar.fr-disabled .fr-btn.fr-active, .fr-popup.fr-disabled .fr-btn, .fr-popup.fr-disabled .fr-btn.fr-active, .fr-modal.fr-disabled .fr-btn, .fr-modal.fr-disabled .fr-btn.fr-active {
    color: gray;
  }

  .fr-toolbar.fr-disabled .fr-btn.fr-dropdown::after, .fr-toolbar.fr-disabled .fr-btn.fr-active.fr-dropdown::after, .fr-popup.fr-disabled .fr-btn.fr-dropdown::after, .fr-popup.fr-disabled .fr-btn.fr-active.fr-dropdown::after, .fr-modal.fr-disabled .fr-btn.fr-dropdown::after, .fr-modal.fr-disabled .fr-btn.fr-active.fr-dropdown::after {
    border-top-color: gray;
  }

  .fr-desktop .fr-command:hover, .fr-desktop .fr-command:focus, .fr-desktop .fr-command.fr-btn-hover, .fr-desktop .fr-command.fr-expanded {
    outline: 0;
    color: #FFF;
  }

  .fr-desktop .fr-command:hover:not(.fr-table-cell), .fr-desktop .fr-command:focus:not(.fr-table-cell), .fr-desktop .fr-command.fr-btn-hover:not(.fr-table-cell), .fr-desktop .fr-command.fr-expanded:not(.fr-table-cell) {
    background: #424242;
  }

  .fr-desktop .fr-command:hover::after, .fr-desktop .fr-command:focus::after, .fr-desktop .fr-command.fr-btn-hover::after, .fr-desktop .fr-command.fr-expanded::after {
    border-top-color: #FFF;
  }

  .fr-desktop .fr-command.fr-selected:not(.fr-table-cell), .fr-desktop .fr-command:active {
    color: #FFF;
    background: #4f4f4f;
  }

  .fr-desktop .fr-command.fr-active:hover, .fr-desktop .fr-command.fr-active:focus, .fr-desktop .fr-command.fr-active.fr-btn-hover, .fr-desktop .fr-command.fr-active.fr-expanded {
    background: #424242;
  }

  .fr-desktop .fr-command.fr-active:active {
    background: #4f4f4f;
  }

  .fr-toolbar.fr-mobile .fr-command.fr-blink, .fr-popup.fr-mobile .fr-command.fr-blink {
    background: #4f4f4f;
  }

  .fr-command.fr-btn.fr-options.fr-btn-hover, .fr-command.fr-btn.fr-options:hover, .fr-command.fr-btn.fr-options:focus {
    border-left: solid 1px #FAFAFA;
  }

  .fr-command.fr-btn + .fr-dropdown-menu {
    background: #353535;
    -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }

  .fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a {
    color: inherit;
  }

  .fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a svg path {
    fill: #FFF;
  }

  .fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a.fr-active {
    background: #4f4f4f;
  }

  .fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a.fr-disabled {
    color: gray;
  }

  .fr-command.fr-btn.fr-active + .fr-dropdown-menu {
    -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }

  .fr-bottom > .fr-command.fr-btn + .fr-dropdown-menu {
    border-radius: 2px 2px 0 0;
    -moz-border-radius: 2px 2px 0 0;
    -webkit-border-radius: 2px 2px 0 0;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }

  .fr-modal {
    color: #FFF;
  }

  .fr-modal .fr-modal-wrapper {
    background: #353535;
    -webkit-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
    border: 0;
  }

  .fr-modal .fr-modal-wrapper .fr-modal-head {
    background: #353535;
    border-bottom: solid #efefef 1px;
  }

  .fr-modal .fr-modal-wrapper div.fr-modal-body:focus {
    outline: 0;
  }

  .fr-modal .fr-modal-wrapper div.fr-modal-body button.fr-command {
    color: #0098f7;
  }

  .fr-modal .fr-modal-wrapper div.fr-modal-body button.fr-command:hover, .fr-modal .fr-modal-wrapper div.fr-modal-body button.fr-command:focus {
    background: #424242;
    color: #0098f7;
  }

  .fr-modal .fr-modal-wrapper div.fr-modal-body button.fr-command:active {
    background: #4f4f4f;
    color: #0098f7;
  }

  .fr-modal .fr-modal-wrapper div.fr-modal-body button::-moz-focus-inner {
    border: 0;
  }

  .fr-desktop .fr-modal-wrapper .fr-modal-head i:hover {
    background: #424242;
  }

  .fr-overlay {
    background: #000;
  }

  .fr-popup {
    color: #FFF;
    background: #353535;
    -webkit-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  }

  .fr-popup .fr-input-focus {
    background: #363636;
  }

  .fr-popup.fr-above {
    border-top: 0;
  }

  .fr-popup .fr-input-line input[type="text"], .fr-popup .fr-input-line input[type="number"], .fr-popup .fr-input-line textarea {
    border: solid 1px #999999;
    color: #FFF;
    background: #353535;
  }

  .fr-popup .fr-input-line input[type="text"]:hover, .fr-popup .fr-input-line input[type="number"]:hover, .fr-popup .fr-input-line textarea:hover {
    border: solid 1px #515151;
  }

  .fr-popup .fr-input-line input[type="text"]:focus, .fr-popup .fr-input-line input[type="number"]:focus, .fr-popup .fr-input-line textarea:focus {
    border: solid 2px #0098f7;
  }

  .fr-popup .fr-input-line input + label, .fr-popup .fr-input-line textarea + label {
    background: #353535;
  }

  .fr-popup .fr-input-line input.fr-not-empty + label, .fr-popup .fr-input-line textarea.fr-not-empty + label {
    color: gray;
  }

  .fr-popup .fr-buttons.fr-tabs {
    background-color: #686868;
  }

  .fr-popup .fr-action-buttons button.fr-command {
    color: #0098f7;
  }

  .fr-popup .fr-action-buttons button.fr-command:hover, .fr-popup .fr-action-buttons button.fr-command:focus {
    background: #424242;
    color: #0098f7;
  }

  .fr-popup .fr-action-buttons button.fr-command:active {
    background: #4f4f4f;
    color: #0098f7;
  }

  .fr-popup .fr-checkbox input:not(:checked) + span {
    border: solid 2px #999999;
  }

  .fr-popup .fr-checkbox input:not(:checked):active + span {
    background-color: #353535;
  }

  .fr-popup .fr-checkbox input:not(:checked):focus + span, .fr-popup .fr-checkbox input:not(:checked):hover + span {
    border-color: #515151;
  }

  .fr-popup .fr-checkbox input:checked + span {
    background: #0098f7;
    border: solid 2px #0098f7;
  }

  .fr-popup .fr-checkbox input:checked:active + span {
    background-color: #EcF5Ff;
  }

  .fr-toolbar {
    color: var(--color-input-txt-filled);
    background: transparent;
    border: 1px solid var(--color-input-border-active);
  }

  .fr-box.fr-fullscreen .fr-toolbar {
    background-color: var(--color-input-list-bg);
  }

  .fr-toolbar .fr-newline {
    background: #353535;
  }

  .fr-toolbar .fr-more-toolbar {
    background-color: #686868;
  }

  .fr-toolbar .fr-command.fr-btn.fr-open:not(:hover):not(:focus):not(:active) {
    background: #686868;
  }

  .fr-toolbar.fr-inline {
    -webkit-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
    border: 0;
  }

  .fr-toolbar.fr-inline.fr-above {
    border-top: 0;
  }

  .fr-toolbar.fr-top {
    border-bottom: 0;
  }

  .fr-toolbar.fr-bottom {
    border-top: 0;
  }

  .fr-toolbar.fr-bottom.fr-toolbar-open .fr-newline {
    background: #FFF;
  }

  .fr-separator {
    background: #595959;
  }

  .fr-second-toolbar {
    color: var(--color-input-txt-filled);
    background: transparent;
    border: 1px solid var(--color-input-border-active);
    border-top: 0;
  }

  .fr-box.fr-fullscreen .fr-second-toolbar {
    background-color: var(--color-input-list-bg);
  }

  #fr-logo > span {
    color: #b1b2b7;
  }

  #fr-logo:hover > span, #fr-logo:focus > span {
    color: #0098f7;
  }

  #fr-logo:hover > svg .fr-logo, #fr-logo:focus > svg .fr-logo {
    fill: #0098f7;
  }
}

.fr-view {
  a {
    color: var(--color-brand-secondary) !important;
  }

  strong, b {
    font-weight: bold;
  }

  u {
    text-decoration: underline;
  }

  em {
    font-style: italic;
  }

  ol, ul {
    list-style: auto;
    margin-left: 20px;
  }
}
