.pu-form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: $spacing-4;
}

.pu-form-group {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  gap: $spacing-4 $spacing-3;

  & > *:not(.pu-hard-control) {
    flex: 1 1 auto;
    width: auto;
    min-width: 80px;
  }

  @include breakpoint(sm) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    &_centered {
      align-items: center;
      gap: $spacing-4 $spacing-5;
    }
  }
}

.pu-hard-control {
  flex: 0 0 auto;
  width: auto;
  min-width: 20px;
}

.pu-form-btn {
  margin-top: rem(6px) !important;
}
