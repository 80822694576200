@import "@ui/styles/utilities";

@mixin active-label {
  @include font-regular-12;

  color: var(--color-input-txt-label);
  top: -1px;
  left: $spacing-2;
  padding: 0 $spacing-1;

  @include breakpoint(md) {
    @include font-regular-14;
  }
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background-clip: content-box !important;
}

.pu-control {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 1;

  @include input-autofill-rewrite;


  &[disabled] {
    opacity: .3;

    .pu-control__icon {
      cursor: initial;
      pointer-events: none;
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &__input {
    @include font-medium-14;

    font-family: $font-family-roboto;
    padding: $spacing-3 11px $spacing-2;
    border: 1px solid transparent;
    border-radius: $border-radius-1;
    color: var(--color-input-txt-filled);
    background-color: transparent;
    margin-top: 6px;

    &::placeholder {
      color: var(--color-input-txt-placeholder);
      font-weight: 400;
    }

    &:focus {
      outline: none;

      + .pu-control__label {
        @include active-label;

        &.pu-control__label_small {
          @include font-regular-12;
        }
      }

      ~ .pu-control__textarea-grab {
        background-color: var(--color-input-border-focus);

        &:after,
        &:before {
          background-color: var(--color-input-border-focus);
        }
      }
    }

    &:read-only {
      pointer-events: none;

      ~ .pu-control__textarea-grab {
        background-color: var(--color-input-border-uneditable);

        &:after,
        &:before {
          background-color: var(--color-input-border-uneditable);
        }
      }
    }

    &_invalid, &_invalid:focus, &_invalid.pu-control__input:read-only {
      ~ .pu-control__textarea-grab {
        background-color: var(--color-additionals-danger);

        &:after,
        &:before {
          background-color: var(--color-additionals-danger);
        }
      }
    }

    &_invalid:focus + .pu-control__label, &_invalid.pu-control__input:read-only + .pu-control__label {
      color: var(--color-additionals-danger);
    }

    &_padding {
      padding-right: 42px;
    }

    &_sum {
      @include font-medium-16;

      padding: 10px 35px 6px 11px;
      width: 100%;

      &::placeholder {
        @include font-regular-14;

        font-weight: 400;
      }
    }

    &_textarea {
      resize: vertical;
      min-height: 40px;

      &::-webkit-resizer {
        display: none;
      }

      .pu-text-area_resize-disabled & {
        resize: none;
      }
    }

    &_right {
      text-align: right;
      padding-right: 31px;
    }
  }

  &__label {
    @include font-regular-14;

    color: var(--color-input-txt-placeholder);
    position: absolute;
    transition: .1s linear;
    left: 11px;
    top: 18px;
    pointer-events: none;

    &_active {
      @include active-label;

      &.pu-control__label_small {
        @include font-regular-12;
      }
    }

    &_invalid:is(&_active) {
      color: var(--color-additionals-danger);
    }
  }

  &__icon {
    position: absolute;
    right: $spacing-3;
    top: calc(50% + 5px);
    transform: translateY(-50%);
    color: var(--color-input-txt-placeholder);

    &_tooltip {
      cursor: pointer;
    }
  }

  &__currency {
    position: absolute;
    top: calc(50% + 5px);
    right: $spacing-3;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    text-align: center;
    color: var(--color-input-txt-filled);

    &:before {
      content: '';
      position: absolute;
      top: -2px;
      left: -8px;
      width: 1px;
      height: calc(100% + 4px);
      background-color: var(--color-input-border-active);
      pointer-events: none;
    }

    &_right:before {
      display: none;
    }
  }

  &__hint {
    @include font-regular-12;

    color: var(--color-input-hint-txt);
    position: absolute;
    bottom: -20px;
    padding-left: $spacing-3;

    .pu-accrual-user-form__amount & {
      padding-left: 0;
    }

    &:before {
      @include circle-before-hint;

      background-color: var(--color-input-hint-txt);

      .pu-accrual-user-form__amount & {
        content: none;
      }
    }

    &_invalid {
      color: var(--color-additionals-danger);

      &:before {
        background-color: var(--color-additionals-danger);
      }
    }

    &_input {
      margin-top: $spacing-2;
      position: relative;
      bottom: 0;

      &:before {
        transform: none;
        top: 6px;
      }
    }
  }

  &__textarea-grab {
    position: absolute;
    bottom: 10px;
    right: 1px;
    height: 1px;
    width: 19px;
    border-radius: 2px;
    transform: matrix(-0.71, 0.7, -0.71, -0.71, 0, 0);
    background-color: var(--color-input-border-active);
    pointer-events: none;

    &:before,
    &:after {
      content: '';
      position: absolute;
      height: 1px;
      border-radius: 2px;
      background-color: var(--color-input-border-active);
    }

    &:before {
      left: 3px;
      top: -3px;
      width: 13px;
    }

    &:after {
      left: 6px;
      top: -6px;
      width: 7px;
    }

    &_invalid {
      background-color: var(--color-additionals-danger);

      &:after,
      &:before {
        background-color: var(--color-additionals-danger);
      }
    }
  }
}
