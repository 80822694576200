.mat-mdc-checkbox {
  .mdc-checkbox__background {
    border: 1px solid var(--color-checkbox-border) !important;
    border-radius: $border-radius-1;

    svg {
      width: $spacing-3;
      height: $spacing-3;
      margin: auto;
    }
  }
  
  &-checked {
    .mdc-checkbox__background {
      border: 0 !important;
      box-shadow: 1px 1px 2px 0px rgba(13, 16, 22, 0.40) inset;
      background-color: var(--color-checkbox-checked-bg) !important;
    }
  }
}
