@import "../colors";
@import "../shadows";

:root {
  color-scheme: light;

  --color-brand-primary: #{$red};
  --color-brand-secondary: #{$teal};
  --color-additionals-danger: #{$red};
  --color-additionals-success: #{$teal};
  --color-additionals-warning: #{$yellow};
  --color-additionals-info: #{$blue};

  --color-transparent: transparent;

  --color-border: #{$gray-11};

  // Buttons
  --color-btn-txt: #{$white};
  --color-btn-txt-pressed: #{$dark-gray-7};
  --color-btn-bg-pressed: #{$milano-red};

  --color-btn-primary-bg: #{$red};
  --color-btn-primary-bg-hover: #{$grenadier-red};

  --color-btn-secondary-bg: #{$dark-gray-3};
  --color-btn-secondary-bg-hover: #{$red};

  --color-btn-tertiary-bg: #{$dark-gray-4};
  --color-btn-tertiary-bg-hover: #{$red};

  --color-btn-quaternary: #{$dark-gray-5};
  --color-btn-quaternary-hover: #{$dark-gray-3};
  --color-btn-quaternary-pressed: #{$dark-gray-4};

  // Backgrounds
  --color-bg-1: #{$gray-9};
  --color-bg-2: #{$gray-7};
  --color-bg-3: #{$white};
  --color-bg-4: #{$gray-8};
  --color-bg-4x: #{$gray-4};
  --color-bg-5: #{$gray-5};
  --color-bg-6: #{$gray-4};

  // Shadows
  --shadow-header-top: #{$box-shadow-to-bottom};
  --shadow-header-bottom: #{$box-shadow-to-bottom-light};
  --shadow-navbar: #{$box-shadow-to-top};
  --shadow-control-dropdown: #{$box-shadow-mini};

  // Gradients
  --gradients-skeleton-blur: linear-gradient(
      93.37deg,
      rgba(255, 255, 255, 0) 0.89%,
      rgba(255, 255, 255, 0.6) 48.66%,
      rgba(255, 255, 255, 0) 96.17%
  );
  --gradient-tab-highlighting: linear-gradient(90deg, rgba(255, 36, 0, 0) 0%, #ff2400 50.52%, rgba(255, 36, 0, 0) 100%);
  --gradient-horiz-tab-scroll-prev: linear-gradient(90deg, #f4f5fa 0%, rgba(244, 245, 250, 0.00) 100%);
  --gradient-horiz-tab-scroll-next: linear-gradient(270deg, #f4f5fa 0%, rgba(244, 245, 250, 0.00) 100%);
  --gradient-horiz-tab-scroll-prev-3: linear-gradient(90deg, #{$white} 0%, #{$white} 10%, rgba(244, 245, 250, 0.00) 100%);
  --gradient-horiz-tab-scroll-next-3: linear-gradient(270deg, #{$white} 0%, #{$white} 10%, rgba(244, 245, 250, 0.00) 100%);
  --gradient-border-hover: linear-gradient(139.58deg, #00cfa6 0%, rgba(0, 207, 166, 0) 100%);
  --gradient-divider-vertical: linear-gradient(180deg, rgba(30, 35, 46, 0) 0%, rgba(128, 133, 143, 0.252417) 27.6%, rgba(128, 133, 143, 0.5) 52.62%, rgba(128, 133, 143, 0.291283) 74.48%, rgba(13, 16, 22, 0) 100%);
  --gradient-divider-horizontal: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(157, 155, 155, 0.15145) 27.6%,
      rgba(157, 155, 155, 0.3) 52.62%,
      rgba(157, 155, 155, 0.17477) 74.48%,
      rgba(255, 255, 255, 0) 100%
  );
  --gradient-shadow: linear-gradient(180deg, #f6f6f8 26.76%, rgba(246, 246, 248, 0) 98.32%);
  --gradient-shadow-rev: linear-gradient(0deg, #f6f6f8 26.76%, rgba(246, 246, 248, 0) 98.32%);
  --gradient-shadow-to-top: linear-gradient(0deg, #f6f6f8 0%, rgba(27, 31, 40, 0.00) 100%);

  // Text and icon
  --color-txt-icon-1: #{$gray-1};
  --color-txt-icon-2: #{$gray-2};
  --color-txt-icon-3: #{$dark-gray-5};
  --color-txt-icon-4: #{$gray-4};
  --color-txt-icon-5: #{$white};
  --color-txt-icon-6: #{$dark-gray-3};

  // Support
  --color-support-menu-bg-hover: #{$gray-6};

  // Balance
  --color-balance-txt: #{$dark-gray-4};
  --color-balance-horizontal-txt: #{$dark-gray-2};
  --color-balance-icon: #{$dark-gray-6};
  --color-balance-bg: #{$gray-8};
  --color-balance-skin-share-bg: #{$dark-gray-3};
  --color-balance-skin-txt: #{$white};
  --gradient-balance-skin-border: linear-gradient(139.58deg, #ff2400 0%, rgba(0, 207, 166, 0) 100%);

  // Theme switcher
  --color-theme-switcher-border: #{$dark-gray-2};
  --color-theme-switcher-slider-bg: #{$dark-gray-2};
  --color-theme-switcher-icon: #{$white};

  // slide toggle
  --color-slide-toggle-track-active: #{$teal};
  --color-slide-toggle-track: #{$dark-gray-4};
  --shadow-slide-toggle-track: inset -1px 1px 2px rgba(13, 16, 22, 0.4);
  --gradient-slide-toggle-handle: linear-gradient(144.73deg, #e3e3e3 15.19%, #fff 56.91%);
  --color-slide-toggle-handle-bg: #{$white};
  --color-slide-toggle-handle-border: #{$dark-gray-8};
  --color-slide-toggle-handle-border-active: #{$teal};

  // Tabs
  --color-tabs-icon: #{$gray-3};
  --color-tabs-icon-hover: #{$gray-1};
  --color-tabs-icon-active: #{$red};
  --color-tabs-txt: #{$gray-3};
  --color-tabs-txt-active-1: #{$red};
  --color-tabs-txt-hover-1: #{$gray-1};
  --color-tabs-divider: #{$gray-10};

  // Sub category tabs
  --color-sub-category-txt: #{$dark-gray-5};
  --color-sub-category-txt-active: #{$white};
  --color-sub-category-txt-hover: #{$dark-gray-3};
  --color-sub-category-tab-strk: #{$dark-gray-5};
  --color-sub-category-tab-strk-hover: #{$dark-gray-3};
  --color-sub-category-tab-strk-active: #{$dark-gray-4};
  --color-sub-category-tab-bg: #{$dark-gray-4};

  // Label
  --color-label-in-bottom-text: #{$white};
  --color-label-in-bottom-bg: rgba(13, 16, 22, 0.65);
  --color-label-txt: #{$white};
  --color-label-new-bg: rgba(0, 207, 166, 0.6);
  --color-label-exclusive-bg: rgba(255, 36, 0, 0.6);
  --color-label-freespins-bg: rgba(13, 16, 22, 0.6);
  --color-label-game-tile: #{$gray-5};
  --color-label-game-empty-star: #{$white};
  --color-label-game-darker: rgba(13, 16, 22, 0.7);
  --color-label-winner-bg: rgba(238, 238, 238, 0.95);
  --color-label-winner-bg-hover: #{$gray-6};

  // Filters
  --filter-color-tabs-icon: brightness(0) saturate(100%) invert(34%) sepia(5%) saturate(389%) hue-rotate(155deg) brightness(93%) contrast(88%);
  --filter-color-tabs-icon-hover: brightness(0) saturate(100%) invert(17%) sepia(9%) saturate(213%) hue-rotate(155deg) brightness(90%) contrast(89%);
  --filter-tabs-icon-active: brightness(0) saturate(100%) invert(28%) sepia(100%) saturate(5905%) hue-rotate(6deg) brightness(104%) contrast(105%);
  --filter-brightness-0: brightness(0%);

  // Controls toggle
  --color-toggle-bg: #{$gray-5};
  --color-toggle-bg-active: #{$teal};
  --color-toggle-circle: linear-gradient(141.95deg, #dddddd 25.7%, #939393 100%);
  --color-toggle-circle-active: linear-gradient(141.95deg, #01cfa6 25.7%, #007e65 100%);
  --color-toggle-circle-bg: linear-gradient(144.73deg, #e3e3e3 15.19%, #ffffff 56.91%);
  --color-toggle-circle-strk: linear-gradient(90deg, #ffffff -20.71%, rgba(255, 255, 255, 0) 120.71%);
  --shadow-toggle: inset -1px 1px 1px rgba(0, 0, 0, 0.18);
  --shadow-toggle-active: inset 1px 1px 1px rgba(0, 0, 0, 0.18);
  --shadow-toggle-circle: 1px 1px 1px rgba(0, 0, 0, 0.18);
  --shadow-toggle-circle-active: -1px 1px 1px rgba(0, 0, 0, 0.18);

  // Controls checkbox
  --color-checkbox-bg: #{$gray-6};
  --color-checkbox-bg-active: #{$teal};
  --color-checkbox-strk: #{$gray-5};
  --color-checkbox-txt: #{$gray-4};
  --color-checkbox-point: #{$white};
  --border-checkbox: 1px solid var(--color-checkbox-strk);
  --shadow-checkbox: inset -1px 1px 1px rgba(0, 0, 0, 0.18);
  --shadow-checkbox-active: inset 1px 1px 1px rgba(0, 0, 0, 0.18);
  --shadow-checkbox-icon: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.18));

  // Controls radiobutton
  --color-radiobutton-bg: #{$gray-6};
  --color-radiobutton-bg-active: #{$teal};
  --color-radiobutton-strk: transparent;
  --color-radiobutton-circle-bg: linear-gradient(144.73deg, #e3e3e3 15.19%, #ffffff 56.91%);
  --color-radiobutton-circle-bg-active: linear-gradient(141.95deg, #01cfa6 25.7%, #007e65 100%);
  --color-radiobutton-circle-strk: #{$white};

  // Controls shadows
  --shadow-controls-inner-right: -1px 1px 1px 0px #0000002e inset;
  --shadow-controls-inner-left: 1px 1px 1px 0px #0000002e inset;
  --shadow-controls-to-right: 1px 1px 1px 0px #0000002e;

  // Inputs
  --color-input-border-active: #{$gray-5};
  --color-input-border-focus: #{$gray-3};
  --color-input-border-uneditable: #{$gray-6};
  --color-input-bg-label-1: #{$white};
  --color-input-bg-label-2: #{$gray-7};
  --color-input-bg-label-3: #{$gray-6};
  --color-input-txt-label: #{$gray-3};
  --color-input-txt-label-focus: #{$gray-3};
  --color-input-txt-placeholder: #{$gray-4};
  --color-input-txt-filled: #{$gray-1};
  --color-input-hint-txt: #{$gray-3};
  --color-input-hint-icon: #{$teal};
  --color-input-hint-bg: #{$gray-6};
  --color-input-list-bg: #{$gray-6};
  --color-input-list-bg-hover: #{$white};
  --color-input-list-bg-active: #{$white};
  --color-input-list-txt: #{$gray-3};
  --color-input-list-txt-hover: #{$gray-3};
  --color-input-list-txt-active: #{$gray-1};
  --color-input-list-txt-disabled: #{$gray-4};
  --color-input-list-icon: #{$gray-4};
  --color-input-list-icon-hover: #{$gray-3};

  // Popovers
  --popover-shadow: 1px 1px 3px 0px rgba(39, 43, 53, 0.15);

  //POP-UPS
  --color-pop-up-bg-header: #{$white};
  --color-pop-up-bg-body: #{$gray-7};
  --color-pop-up-line: #{$gray-6};
  --color-pop-up-bg-toast: #{$white};
  --color-pop-up-txt-header: #{$gray-1};
  --color-pop-up-txt-body: #{$gray-2};
  --color-pop-up-ic-close: #{$gray-5};
  --color-pop-up-overlay-darken: rgba(13, 16, 22, 0.7);

  // Product
  --color-product-color-selected: #{$teal};

  // Cart
  --color-cart-form-tab: #{$dark-gray-5};

  // Admin Table
  --color-table-cell-header-bg: #{$gray-5};
  --color-table-cell-bg: #{$gray-6};
  --color-table-cell-odd-bg: #{$gray-7};
  --color-table-cell-dialog-bg: #{$gray-6};
  --color-table-cell-dialog-odd-bg: #{$gray-7};

  // Statuses
  --color-status-new: #{$blue};
  --color-status-inProgress: #{$blue};
  --color-status-confirmed: #{$yellow};
  --color-status-shipping: #{$yellow};
  --color-status-delivered: #{$yellow};
  --color-status-inReview: #{$yellow};
  --color-status-completed: #{$teal};
  --color-status-canceled: #{$red};
  --color-status-rejected: #{$purple};

  // User statuses
  --color-status-probation: #{$yellow};
  --color-status-active: #{$teal};
  --color-status-offboarding: #{$red};

  // Quest progress gradient colors
  --color-quest-progress-default: #{$gray-10};
  --color-quest-in-progress-0: #{$blue};
  --color-quest-in-progress-1: #75ffff;
  --color-quest-in-review-0: #{$yellow};
  --color-quest-in-review-1: #ff360a;
  --color-quest-rejected-0: #{$purple};
  --color-quest-rejected-1: #ff003d;
  --color-quest-completed: #{$teal};
  --color-quest-canceled: #{$red};

  // Avatar
  --color-avatar-border: #{$gray-10};
  --color-avatar-bg: #{$gray-8};
  --color-avatar-text: #{$dark-gray-4};
  --color-avatar-colorful-text: #{$white};
  --color-avatar-colorful-border: #{$gray-7};
  --brightness-avatar-colorful-bg: 20%;

  // Checkbox
  --color-checkbox-border: #{$dark-gray-6};
  --color-checkbox-checked-bg: #{$teal};

  // Blur background
  --blur-bg-color: rgba(226, 226, 226, 0.75);

  // Birthday calendar
  --color-day-date: #{$dark-gray-2};
  --color-day-month: #{$dark-gray-4};
  --color-user-card-bg: #{$white};
  --color-user-card-shadow: 1px 1px 3px 0px rgba(39, 43, 53, 0.15);
  --color-user-card-border-0: linear-gradient(139.58deg, rgba(64, 210, 131, 1) 0%, rgba(0, 133, 255, 1) 100%);
  --color-user-card-border-1: linear-gradient(139.58deg, rgba(137, 0, 221, 1) 0%, rgba(221, 0, 212, 1) 100%);
  --color-user-card-border-2: linear-gradient(139.58deg, rgba(64, 210, 131, 1) 0%, rgba(0, 133, 255, 1) 100%);
  --color-user-card-border-3: linear-gradient(139.58deg, rgba(249, 137, 24, 1) 0%, rgba(242, 34, 34, 1) 100%);

  // Timer
  --color-timer-number: #{$dark-gray-2};
  --color-timer-text: #{$dark-gray-5};
  --color-timer-expired-number: #{$dark-gray-6};
  --color-timer-expired-text: #{$dark-gray-7};

  // Org tree
  --color-orgtree-header-bg: rgba(255, 255, 255, 0.75);
  --color-orgtree-link: #{$dark-gray-4};
  --color-orgtree-position: #{$dark-gray-1};
  --color-orgtree-position-hover: #{$teal};
  --color-orgtree-position-bg: #{$white};
  --color-orgtree-avatar-border: var(--color-avatar-colorful-border);
  --color-orgtree-avatar-border-hover: #{$teal};
  --color-orgtree-avatar-text: var(--color-avatar-colorful-text);
  --color-orgtree-details-header-bg: #{$white};
  --color-orgtree-details-body-bg: #{$gray-9};
  --color-orgtree-popover-bg: #{$white};

  // Texture
  --texture-url: url("/assets/images/texture-bg-light.svg");

  color: $black;
  background: var(--color-bg-1);
}
